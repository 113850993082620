import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Authentication from 'pages/Authentication.js'
import { Provider } from 'react-redux'
import 'assets/scss/black-dashboard-pro-react.scss?v=1.2.0'
import 'assets/demo/demo.css'
import store from 'redux/store'

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Switch>
        <Route path='/' render={props => <Authentication {...props} />} />
      </Switch>
      {/* <Footer /> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
