// Api
// Main Url
export const MAIN_URL = 'affilih.com'
export const STORE_MAIN_URL = 'store.affilih.com'
export const FULL_MAIN_URL = 'https://affilih.com'
export const DOMAIN = window.location.origin

// export const URL = !(window.location.origin.includes("localhost") || window.location.origin.includes("local.host")) ? "https://affilih.com/v1/api/authGate" : "http://localhost:4500/v1/api/authGate";
// export const URLIAFF = 'https://affilih.com/v1/api'
export const URLIMG = "https://storage.googleapis.com/affilih_storage/images/";
export const URL = !(window.location.origin.includes("localhost") || window.location.origin.includes("local.host") || window.location.origin.includes("ngrok.io")) ? "https://affilih.com/v1/api/authGate" : "http://localhost:4500/v1/api/authGate";
export const URLIAFF = !(window.location.origin.includes("localhost") || window.location.origin.includes("local.host") || window.location.origin.includes("ngrok.io")) ? "https://affilih.com/v1/api" : "http://localhost:4500/v1/api";
