const TOKEN_NAME = "TOKEN_NAME";
export const HandelMyData = (data, expected) => {
  if (typeof data === expected) {
    return data;
  }
  if (expected === "array") {
    return [];
  } else {
    return {};
  }
};

export const HandelMyError = (error) => {
  if (typeof error?.response?.data?.error === "string") {
    if (error?.response?.data?.removeToken) {
      localStorage.removeItem(TOKEN_NAME);
    }
    return error?.response?.data?.error;
  }
  if (error.response.status === 404) {
    return "غير موجود او ربما تم الحذف - خطأ غير مسمي";
  }
  return error.response.statusText;
};
