import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  FormFeedback,
  UncontrolledAlert,
  Row,
  Col,
} from "reactstrap";
import { GoogleAPI, GoogleLogin } from "react-google-oauth";
import { URLIMG } from "../redux/api/api.conf";
import { FcGoogle } from "react-icons/fc";
import { VscAdd } from "react-icons/vsc";
import { BsCheckAll } from "react-icons/bs";
import { BiCurrentLocation, BiStore } from "react-icons/bi";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import Avatar from "react-avatar";
import queryString from "query-string";
import { Img } from "react-image";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { checkUserIsRegistered, registerUser, checkClientIsRegistered, registerClient, saveAffiliator } from "../redux/config";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useGeoLocation from "react-ipgeolocation";

import ar from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css";

const Authentication = (props) => {
  const {
    checkUserIsRegistered,
    registerUser,
    checkClientIsRegistered,
    registerClient,
    saveAffiliator,
    auth: { register, saveLoadingAffilitor, saveSuccessAffilitor, saveFailedAffilitor },
  } = props;
  const [googleData, setGoogleData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [country, setCountry] = useState("EG");
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [secPhone, setSecPhone] = useState(null);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [isCoorDone, setCoreStatus] = useState(false);

  const countries = Country.getAllCountries()?.map((ca) => {
    return { value: ca.isoCode, label: ca.name };
  });

  useEffect(() => {
    if (saveSuccessAffilitor !== null) {
      window.location.href = saveSuccessAffilitor?.redirectURL;
    }
  }, [saveSuccessAffilitor]);

  useEffect(() => {
    if (register?.success?.redirectURL) {
      window.location.href = register?.success?.redirectURL;
    }
  }, [register]);

  const states = State.getStatesOfCountry(country)?.map((ca) => {
    return { value: ca.isoCode, label: ca.name };
  });

  const cites = [
    ...City.getCitiesOfState(country, state)?.map((ca) => {
      return { value: String(ca.name), label: String(ca.name) };
    }),
    { value: "other", label: "--Other--" },
  ];

  const changeCountryHandler = (state) => {
    setCountry(state.value);
    setState(null);
    setCity(null);
  };

  const changeStateHandler = (state) => {
    setLat(State.getStateByCodeAndCountry(state.value, country)?.latitude);
    setLong(State.getStateByCodeAndCountry(state.value, country)?.longitude);

    setState(state.value);
    setCity(null);
  };
  const changeCityHandler = (city) => {
    setCity(String(city.value));
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderColor: "#344675",
    }),
    singleValue: (base, state) => ({
      ...base,
      // color: '#fff',
      background: "transparent",
    }),
  };

  let params = queryString.parse(props.location.search);

  const handleFormSubmit = (fromData) => {
    if (params.source === "system") {
      registerUser({
        token: accessToken,
        ...fromData,
        ...params,
      });
    } else {
      registerClient({
        token: accessToken,
        ...fromData,
        ...params,
      });
    }
  };

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    var crd = pos.coords;
    setLat(String(crd.latitude));
    setLong(String(crd.longitude));
    setCoreStatus(true);
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  const getAccessToken = (e) => {
    if (e[Object.keys(e)[1]]?.access_token !== undefined) {
      return e[Object.keys(e)[1]]?.access_token;
    }
    if (e[Object.keys(e)[2]]?.access_token !== undefined) {
      return e[Object.keys(e)[2]]?.access_token;
    }
    if (e[Object.keys(e)[0]]?.access_token !== undefined) {
      return e[Object.keys(e)[0]]?.access_token;
    }
    return null;
  };
  // let clientId =
  //   '303396594421-s19gt2k27kfo6iqkg8hhjhigu48i0rsf.apps.googleusercontent.com'
  let clientId = "303396594421-s8ik4kmcic3ke0e8qqc8edqd8j4mib9d.apps.googleusercontent.com";
  let cApi = useGeoLocation()?.country?.toLowerCase() || "eg";

  return (
    <Container
      size="md"
      className="mx-auto rtl d-flex justify-content-center flex-column bg-white text-darker pb-0 pb-md-5 mt-md-6 mt-0 mb-5 rounded-top position-relative"
      style={{ maxWidth: "500px" }}
    >
      {(() => {
        if (params.source === undefined) {
          return (
            <Card className="text-center rounded-0 bg-transparent rounded-0 shadow-none mt-3 mb-0">
              <CardTitle tag="h2" className="font-weight-light mt-2 mb-0 text-darker mb-5">
                الانضمام كا مسوق الي AFFILIH
              </CardTitle>
              {(() => {
                if (saveLoadingAffilitor) {
                  return <CardTitle className="">لحظة من فضلك، جاري التحقق ...</CardTitle>;
                } else if (saveFailedAffilitor !== null) {
                  return (
                    <UncontrolledAlert color="danger" className="text-darker">
                      {saveFailedAffilitor}
                    </UncontrolledAlert>
                  );
                }
              })()}

              <GoogleAPI clientId={clientId}>
                <div
                  style={{
                    maxWidth: "100%",
                    display: "inline-block",
                  }}
                  className="w-100 rounded overflow-hidden border-bottom"
                >
                  <div>
                    <GoogleLogin
                      onLoginSuccess={(e) => {
                        saveAffiliator({
                          token: getAccessToken(e),
                        });
                      }}
                      onLoginFailure={(e) => {
                        console.log(e);
                      }}
                      backgroundColor="#fff"
                      width="100%"
                      text={
                        <>
                          <FcGoogle className="ml-2" size="25px" />
                          <span className="font-cairo font-weight-bold text-darker">التسجيل كمسوق عبر جوجل</span>
                        </>
                      }
                    />
                  </div>
                </div>
              </GoogleAPI>
              <Button
                to="a"
                href="https://affilih.com/"
                color="info"
                className="mt-1 rtl rounded w-100 d-flex justify-content-center flex-row flex-nowrap px-2 align-items-center animation-on-hover btn-simple border-0 mb-5"
                // className='rounded font-weight-bolder bg-neutral-first text-first'
              >
                <BiStore size="20px" className="ml-1" />
                <span className=" text-nowrap font-cairo font-weight-light">او انضم كتاجر</span>
              </Button>
            </Card>
          );
        } else {
          return (
            <>
              <Card className="text-center rounded-0 bg-transparent rounded-0 shadow-none mt-3 mb-0">
                <CardTitle tag="h2" className="font-weight-light mt-2 mb-0 text-darker">
                  بوابة التحقق من الهوية
                </CardTitle>
              </Card>
              <Card className="card-lock text-center mb-0 rounded-0 shadow-none bg-white text-darker">
                <CardHeader>
                  <Img
                    className=""
                    alt={`${params.sourceName}`}
                    src={[URLIMG + `${params.sourceImg}`]}
                    container={(children) => children}
                    unloader={<Avatar name={params.sourceName} size="125" round={true} />}
                    loader={
                      <div className="rounded-circle" style={{ marginTop: "-5em" }}>
                        <SkeletonTheme width="100%" height="100%" color="#f0f0f0" highlightColor="#fff">
                          <Skeleton duration={0.5} width={128} height={128} className="rounded-circle" />
                        </SkeletonTheme>
                      </div>
                    }
                  />
                </CardHeader>
                <CardBody className="">
                  <CardTitle tag="h4" className=" text-darker">
                    {params.sourceName}
                  </CardTitle>
                </CardBody>
              </Card>
              {(() => {
                if (googleData === null) {
                  return (
                    <div className="mb-5">
                      <GoogleAPI clientId={clientId}>
                        <div>
                          <div>
                            <GoogleLogin
                              onLoginSuccess={(e) => {
                                if (params?.source === "system") {
                                  setGoogleData(e);
                                  setAccessToken(getAccessToken(e));
                                  checkUserIsRegistered({
                                    token: getAccessToken(e),
                                    ...params,
                                  });
                                } else if (params?.source === "store") {
                                  setGoogleData(e);
                                  setAccessToken(getAccessToken(e));
                                  checkClientIsRegistered({
                                    token: getAccessToken(e),
                                    ...params,
                                  });
                                } else {
                                  setGoogleData(e);
                                  setAccessToken(getAccessToken(e));
                                }
                              }}
                              onLoginFailure={(e) => {
                                console.log(e);
                              }}
                              backgroundColor="#fff"
                              width="100%"
                              text={
                                <>
                                  <FcGoogle className="ml-2" size="25px" />
                                  <span className="font-cairo font-weight-bold text-darker">التسجيل عبر جوجل</span>
                                </>
                              }
                            />
                          </div>
                        </div>
                      </GoogleAPI>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (register?.failed?.error) {
                  return (
                    <UncontrolledAlert color="danger" className="text-darker">
                      {register?.failed?.error}
                    </UncontrolledAlert>
                  );
                }
              })()}
              {(() => {
                if (googleData !== null) {
                  return (
                    <>
                      <Card className="shadow-none bg-white">
                        <CardBody>
                          {(() => {
                            if (register?.loading === true) {
                              return <CardTitle className="text-success">لحظة من فضلك، جاري التحقق ...</CardTitle>;
                            }
                          })()}
                          <CardTitle className="text-success">{register?.success?.message}</CardTitle>
                          {(() => {
                            if (register?.success?.fillForm) {
                              return (
                                <Formik
                                  initialValues={{
                                    phone,
                                    ...(params.source !== "system" && {
                                      country,
                                      state,
                                      city,
                                      secPhone,
                                      address,
                                      lat,
                                      long,
                                    }),
                                  }}
                                  enableReinitialize="true"
                                  onSubmit={handleFormSubmit}
                                  validationSchema={Yup.object().shape({
                                    ...(params.source !== "system" && {
                                      secPhone: Yup.string().max(15, "الحد الاقصي 15 ارفام.").nullable(),
                                      address: Yup.string().min(10, "الحد الادني 10 حروف").required("العنوان ضروري للتسجيل"),
                                    }),
                                    phone: Yup.string()
                                      .min(10, "الحد الادني 10 ارفام.")
                                      .max(15, "الحد الاقصي 15 ارفام.")
                                      .required("رقم الهاتف ضروري")
                                      .nullable(),
                                  })}
                                >
                                  {({ handleChange, handleSubmit, isValid, isSubmitting, handleBlur, errors, touched }) => (
                                    <Form className="w-100">
                                      {(() => {
                                        if (params.source !== "system") {
                                          return (
                                            <>
                                              <FormGroup>
                                                <Label className="text-darker" for="state">
                                                  اختر الدولة
                                                </Label>
                                                <Select
                                                  styles={customStyles}
                                                  tag={Input}
                                                  className="bg-transparent"
                                                  placeholder="بحث..."
                                                  id="country"
                                                  autoComplete="off"
                                                  // Country.getCountryByCode(country)
                                                  defaultValue={{
                                                    value: Country.getCountryByCode(country)?.isoCode,
                                                    label: Country.getCountryByCode(country)?.name,
                                                  }}
                                                  // value={Country.getCountryByCode(state)}
                                                  options={countries}
                                                  onChange={changeCountryHandler}
                                                />
                                              </FormGroup>
                                              {(() => {
                                                if (country !== null) {
                                                  return (
                                                    <>
                                                      <FormGroup>
                                                        <Label className="text-darker" for="state">
                                                          اختر المحافظة
                                                        </Label>
                                                        <Select
                                                          styles={customStyles}
                                                          id="state"
                                                          autoComplete="off"
                                                          placeholder="بحث..."
                                                          // value={State.getStateByCodeAndCountry(city, state)}
                                                          options={states}
                                                          onChange={changeStateHandler}
                                                        />
                                                      </FormGroup>
                                                      {(() => {
                                                        if (state !== null) {
                                                          return (
                                                            <>
                                                              <FormGroup>
                                                                <Label className="text-darker" for="city">
                                                                  اختر المدينة
                                                                </Label>
                                                                <Select
                                                                  styles={customStyles}
                                                                  id="city"
                                                                  autoComplete="off"
                                                                  placeholder="بحث..."
                                                                  // value={city}
                                                                  options={cites}
                                                                  onChange={changeCityHandler}
                                                                />
                                                              </FormGroup>
                                                              {(() => {
                                                                if (country !== null) {
                                                                  return (
                                                                    <>
                                                                      <FormGroup>
                                                                        <Label className="text-darker" for="phone">
                                                                          رقم الهاتف
                                                                        </Label>
                                                                        <PhoneInput
                                                                          enableAreaCodeStretch
                                                                          enableSearch="false"
                                                                          disableDropdown="false"
                                                                          disableSearchIcon="true"
                                                                          country={country.toLowerCase()}
                                                                          value={phone}
                                                                          onChange={(phone) => {
                                                                            setPhone(phone);
                                                                          }}
                                                                          localization={ar}
                                                                          inputClass="pl-5 mb-3 mb-md-0 w-100 bg-transparent border-default text-dark"
                                                                          buttonClass="border-default bg-transparent text-dark"
                                                                          containerClass="ltr text-dark"
                                                                          // placeholder="رقم الهاتف"
                                                                          id="phone"
                                                                          inputProps={{
                                                                            id: "phone",
                                                                            name: "phone",
                                                                            type: "phone",
                                                                            required: true,
                                                                            invalid: errors.phone && touched.phone ? "true" : "false",
                                                                          }}
                                                                          type="phone"
                                                                          autoComplete="tel"
                                                                          searchClass="w-100 d-flex justify-content-center m-auto rtl text-dark"
                                                                          name="phone"
                                                                          invalid={errors.phone && touched.phone ? "true" : "false"}
                                                                          onBlur={handleBlur}
                                                                        />

                                                                        {errors.phone && touched.phone ? (
                                                                          <FormFeedback className="d-flex">{errors.phone}</FormFeedback>
                                                                        ) : null}
                                                                      </FormGroup>
                                                                      <FormGroup>
                                                                        <Label className="text-darker" for="secPhone">
                                                                          رقم الهاتف الاحتياطي
                                                                        </Label>
                                                                        <PhoneInput
                                                                          enableAreaCodeStretch
                                                                          enableSearch="false"
                                                                          disableDropdown="false"
                                                                          disableSearchIcon="true"
                                                                          country={country.toLowerCase()}
                                                                          value={secPhone}
                                                                          onChange={(secPhone) => {
                                                                            setSecPhone(secPhone);
                                                                          }}
                                                                          localization={ar}
                                                                          inputClass="pl-5 mb-3 mb-md-0 w-100 bg-transparent border-default text-dark"
                                                                          buttonClass="border-default bg-transparent text-dark"
                                                                          containerClass="ltr text-dark"
                                                                          type="phone"
                                                                          autoComplete="off"
                                                                          searchClass="w-100 d-flex justify-content-center m-auto rtl text-dark"
                                                                          id="secPhone"
                                                                          inputProps={{
                                                                            name: "secPhone",
                                                                          }}
                                                                          name="secPhone"
                                                                          invalid={errors.secPhone && touched.secPhone}
                                                                          onBlur={handleBlur}
                                                                        />

                                                                        {errors.secPhone && touched.secPhone ? (
                                                                          <FormFeedback className="d-flex">{errors.secPhone}</FormFeedback>
                                                                        ) : null}
                                                                      </FormGroup>
                                                                    </>
                                                                  );
                                                                }
                                                              })()}

                                                              <FormGroup>
                                                                <Label className="text-darker" for="exampleText">
                                                                  العنوان التفصيلي (الطابق - المنزل - الشارع - علامة مميزة)
                                                                </Label>
                                                                <Input
                                                                  autoComplete="street-address"
                                                                  type="textarea"
                                                                  id="address"
                                                                  name="address"
                                                                  value={address}
                                                                  className="text-dark"
                                                                  onChange={(address) => {
                                                                    setAddress(address.target.value);
                                                                  }}
                                                                  invalid={errors.address && touched.address}
                                                                  onBlur={handleBlur}
                                                                />
                                                                {errors.address && touched.address ? (
                                                                  <FormFeedback className="d-flex">{errors.address}</FormFeedback>
                                                                ) : null}
                                                              </FormGroup>
                                                              <Button
                                                                type="submit"
                                                                className="animation-on-hover font-cairo font-weight-light"
                                                                size="sm"
                                                                color="success"
                                                              >
                                                                <VscAdd size="20px" className="ml-2" />
                                                                تسجيل الحساب
                                                              </Button>

                                                              <Row className="border-top border-default pt-2 mt-3">
                                                                <Col xs="6">
                                                                  <FormGroup className="mb-0">
                                                                    <Label className="text-darker" for="exampleText">
                                                                      خط الطول
                                                                    </Label>
                                                                    <Input
                                                                      className="mb-0 bg-transparent"
                                                                      type="text"
                                                                      id="lat"
                                                                      name="lat"
                                                                      value={lat}
                                                                      disabled={true}
                                                                    />
                                                                  </FormGroup>
                                                                </Col>
                                                                <Col xs="6">
                                                                  <FormGroup className="mb-0">
                                                                    <Label className="text-darker" for="exampleText">
                                                                      خط العرض
                                                                    </Label>
                                                                    <Input
                                                                      className="mb-0 bg-transparent"
                                                                      type="text"
                                                                      id="lat"
                                                                      name="lat"
                                                                      value={long}
                                                                      disabled={true}
                                                                    />
                                                                  </FormGroup>
                                                                </Col>
                                                                <Col sm="12" className=" mt-2">
                                                                  {(() => {
                                                                    if (isCoorDone) {
                                                                      return (
                                                                        <span className="text-success">
                                                                          <BsCheckAll size="20" className="ml-1" />
                                                                          الإحداثيات أكثر دقة الآن.
                                                                        </span>
                                                                      );
                                                                    } else {
                                                                      return (
                                                                        <Button
                                                                          className="btn-link px-0 font-cairo text-primary"
                                                                          color="primary"
                                                                          size="sm"
                                                                          onClick={() => {
                                                                            navigator.geolocation.getCurrentPosition(success, error, options);
                                                                          }}
                                                                        >
                                                                          <BiCurrentLocation size="20" className="ml-1" />
                                                                          اضغط لإحداثيات أكثر دقة
                                                                        </Button>
                                                                      );
                                                                    }
                                                                  })()}
                                                                </Col>
                                                                <Col sm="12" className=" mt-2 mx-1">
                                                                  <CardSubtitle className="mb-2 text-muted">
                                                                    الإحداثيات الدقيقة تساعدنا في تحديد أقرب مخزن لعنوانك.
                                                                  </CardSubtitle>
                                                                </Col>
                                                              </Row>
                                                            </>
                                                          );
                                                        }
                                                      })()}
                                                    </>
                                                  );
                                                }
                                              })()}
                                            </>
                                          );
                                        } else if (cApi !== undefined) {
                                          return (
                                            <>
                                              <FormGroup>
                                                <Label className="text-darker" for="phone">
                                                  رقم الهاتف
                                                </Label>
                                                <PhoneInput
                                                  enableAreaCodeStretch
                                                  enableSearch="false"
                                                  disableDropdown="false"
                                                  disableSearchIcon="true"
                                                  country={cApi}
                                                  value={phone}
                                                  onChange={(phone) => {
                                                    setPhone(phone);
                                                  }}
                                                  localization={ar}
                                                  inputClass="pl-5 mb-3 mb-md-0 w-100 bg-transparent border-default text-dark"
                                                  buttonClass="border-default bg-transparent text-dark"
                                                  containerClass="ltr text-dark"
                                                  // placeholder="رقم الهاتف"
                                                  id="phone"
                                                  inputProps={{
                                                    id: "phone",
                                                    name: "phone",
                                                    type: "phone",
                                                    required: true,
                                                    invalid: errors.phone && touched.phone ? "true" : "false",
                                                  }}
                                                  type="phone"
                                                  autoComplete="tel"
                                                  searchClass="w-100 d-flex justify-content-center m-auto rtl text-dark"
                                                  name="phone"
                                                  invalid={errors.phone && touched.phone ? "true" : "false"}
                                                  onBlur={handleBlur}
                                                />

                                                {errors.phone && touched.phone ? <FormFeedback className="d-flex">{errors.phone}</FormFeedback> : null}
                                              </FormGroup>
                                              <Button type="submit" className="animation-on-hover font-cairo font-weight-light" size="sm" color="success">
                                                <VscAdd size="20px" className="ml-2" />
                                                تسجيل الحساب
                                              </Button>
                                            </>
                                          );
                                        }
                                      })()}
                                    </Form>
                                  )}
                                </Formik>
                              );
                            }
                          })()}
                        </CardBody>
                      </Card>
                    </>
                  );
                }
              })()}
            </>
          );
        }
      })()}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 217.86 60.98"
        className="position-absolute bottom-0 right-0 left-0 w-100 overflow-hidden"
        style={{ marginBottom: "-6em" }}
      >
        <defs>
          <radialGradient
            id="radial-gradient"
            cx="-887.1"
            cy="3483.7"
            r="61.8"
            gradientTransform="translate(1672.67 -768.86) scale(1.76 0.22)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" />
            <stop offset="0.1" stopColor="#2d2d2d" />
            <stop offset="0.23" stopColor="#646464" />
            <stop offset="0.37" stopColor="#949494" />
            <stop offset="0.5" stopColor="#bababa" />
            <stop offset="0.63" stopColor="#d8d8d8" />
            <stop offset="0.76" stopColor="#eee" />
            <stop offset="0.88" stopColor="#fbfbfb" />
            <stop offset="1" stopColor="#fff" />
          </radialGradient>
        </defs>
        <g>
          <g id="Layer_1" data-name="Layer 1">
            <path
              fill="#212529"
              d="M0,7.69a2.06,2.06,0,0,1,0,.77v6.27a1.4,1.4,0,0,0,.25.17,1.81,1.81,0,0,1-.25.35v1.1l.14,0c.21,0,.1.09-.14.19V29.9a9.34,9.34,0,0,0,4-.52c2.95-1.47,3.92.19,4.69,0,3.05-.4,5.3-2.14,5.21.38,1.36.18.45-1,2.92.31,1.95-.66-.31.56,1,.46-1.4-.58,1.48.26,1.5.32a5.74,5.74,0,0,0,1-.27c.07.19.32.48,1,.91,2.62,1.89,1.93,1,2.93,1.23,1.46-.23,1.27.93,2.23.92,2.27-.87,4.14-.89,4.41.06,2.13,1.13,1.06-.47,2.3,0,.46-.21,1.68-1,1.75-.33.08-.16,1.6-.64,1.33.73,1.49.68,2.09,2.34,2.49.07,3.28-.19,6-1.66,9.38-1.4,1.52.48,2.58-1.09,3.9-.9,1.55-1.13,2.77-2.94,4.58-2.13.61-1.67,1.18.72,1.79,0a9.43,9.43,0,0,1,1.56-.31c1.46,2.1,2.64.87,4-.94,2-1.51,4.8-.5,6.94-.31s3.89,2.12,5.84,1.1a46.35,46.35,0,0,0,9.23,2.16,7.65,7.65,0,0,1,3.28.44c1.71-1.27,3.27,3,5,.32,1.51,1.54,3-.49,4.54,0,1.71,1.5,3.41-.41,5.08.56,3.12,2.58,6.41.88,9.63.41,1.76,1.35,3.19-2,4.89-1.21a7.9,7.9,0,0,0,3.33-.26c1.49-.3,2.94-.9,4.43-1.09,2.66-2.05,5.07-1.71,7.93-.54,2.46-.27,4.91-1.25,7.37,0,1.81,1.1,3.51-.29,5.33-.33s3.69.15,5.53,0c1.84.27,3.69-.66,5.53,0a35.93,35.93,0,0,1,5.65.31c1.71.25,2.81-1.69,4.3,1.33,1.24.46,2.34,1.2,4.15,1.08.81.62,2.55-.79,4-.19,2.5-.62,2.47-.53,4.87-.07,2.45-.32,2.93-1.59,4.15.07,5.06.54,3,1.81,9.78-.3,2.27-3,2.19.25,7.63-.7,5.42-.25,0,.39,2.87.32,2.54.77,3-.59,5.81,0,2.14-.55.38-.86,3.05.32a10.05,10.05,0,0,0,3.87-.21V12.8c-1.14-.3-1.23-.46,0-.58V0H0Z"
            />
            <path
              fill="#fff"
              d="M0,7.14a1.77,1.77,0,0,1,0,.71v5.82a1.41,1.41,0,0,0,.25.16,1.69,1.69,0,0,1-.25.33v1l.14,0c.21,0,.1.08-.14.18V27.75a10,10,0,0,0,4-.48c2.95-1.36,3.92.18,4.69,0,3.05-.37,5.3-2,5.21.36,1.36.17.45-.92,2.92.29,1.95-.62-.31.52,1,.43-1.4-.54,1.48.24,1.5.3a6.09,6.09,0,0,0,1-.25c.07.18.32.45,1,.84,2.62,1.75,1.93.9,2.93,1.14,1.46-.21,1.27.86,2.23.85,2.27-.8,4.14-.82,4.41.05,2.13,1.05,1.06-.43,2.3,0,.46-.19,1.68-1,1.75-.31.08-.15,1.6-.59,1.33.67,1.49.63,2.09,2.18,2.49.06,3.28-.18,6-1.54,9.38-1.3,1.52.44,2.58-1,3.9-.84,1.55-1,2.77-2.73,4.58-2,.61-1.55,1.18.67,1.79,0a10.09,10.09,0,0,1,1.56-.29c1.46,1.94,2.64.8,4-.87,2-1.4,4.8-.47,6.94-.29s3.89,2,5.84,1a49.4,49.4,0,0,0,9.23,2,8.2,8.2,0,0,1,3.28.41c1.71-1.18,3.27,2.78,5,.3,1.51,1.43,3-.45,4.54,0,1.71,1.39,3.41-.38,5.08.52,3.12,2.39,6.41.81,9.64.38,1.76,1.25,3.19-1.89,4.89-1.12a8.49,8.49,0,0,0,3.33-.25c1.49-.28,2.94-.83,4.43-1,2.66-1.9,5.07-1.59,7.93-.5,2.46-.25,4.91-1.16,7.37,0,1.81,1,3.51-.27,5.33-.3s3.69.14,5.53,0c1.84.25,3.69-.61,5.53,0a38.69,38.69,0,0,1,5.65.29c1.71.24,2.81-1.56,4.3,1.24a9.23,9.23,0,0,0,4.15,1c.81.57,2.55-.74,4-.17a8,8,0,0,1,4.87-.06c2.45-.3,2.93-1.47,4.15.06,5.06.5,3,1.68,9.78-.28,2.27-2.83,2.19.24,7.63-.65,5.42-.23,0,.36,2.87.3,2.54.72,3-.55,5.81,0,2.14-.51.38-.8,3.05.3a10.81,10.81,0,0,0,3.87-.2V11.87c-1.14-.28-1.23-.42,0-.54V0H0Z"
            />
          </g>
        </g>
      </svg>
    </Container>
  );
};
export default connect((state) => state, {
  checkUserIsRegistered,
  registerUser,
  checkClientIsRegistered,
  registerClient,

  saveAffiliator,
})(Authentication);
