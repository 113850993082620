import { apiCheckUserIsRegistered, apiRegisterUser, apiCheckClientIsRegistered, apiRegisterClient, apiSaveAffiliator } from "../api/";

import { HandelMyError } from "../store/Redux.Hander.js";

const AUTH = "AUTH";

const SAVELOADINGPAFFILITOR = "SAVELOADINGPAFFILITOR";
const SAVESUCCESSAFFILITOR = "SAVESUCCESSAFFILITOR";
const SAVEFAILEDAFFILITOR = "SAVEFAILEDAFFILITOR";

export const checkUserIsRegistered = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH, payload: { loading: true } });
      const { data } = await apiCheckUserIsRegistered(params);
      dispatch({
        type: AUTH,
        payload: {
          loading: false,
          success: { ...data },
        },
      });
    } catch (e) {
      dispatch({
        type: AUTH,
        payload: {
          failed: { loading: false, ...e.response.data },
        },
      });
    }
  };
};

export const checkClientIsRegistered = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH, payload: { loading: true } });
      const { data } = await apiCheckClientIsRegistered(params);
      dispatch({
        type: AUTH,
        payload: {
          loading: false,
          success: { ...data },
        },
      });
    } catch (e) {
      dispatch({
        type: AUTH,
        payload: {
          failed: { loading: false, ...e.response.data },
        },
      });
    }
  };
};

export const registerUser = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH, payload: { loading: true } });
      const { data } = await apiRegisterUser(params);
      dispatch({
        type: AUTH,
        payload: {
          loading: false,
          success: { ...data },
        },
      });
    } catch (e) {
      dispatch({
        type: AUTH,
        payload: {
          failed: { loading: false, ...e.response.data },
        },
      });
    }
  };
};
export const registerClient = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH, payload: { loading: true } });
      const { data } = await apiRegisterClient(params);
      dispatch({
        type: AUTH,
        payload: {
          loading: false,
          success: { ...data },
        },
      });
    } catch (e) {
      dispatch({
        type: AUTH,
        payload: {
          failed: { loading: false, ...e.response.data },
        },
      });
    }
  };
};

const INITIAL_STATE = {
  register: {},
  saveLoadingAffilitor: false,
  saveSuccessAffilitor: null,
  saveFailedAffilitor: null,
};

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
        ...(action.payload !== null && {
          register: { ...state?.register, ...action?.payload },
        }),
        ...(action.payload === null && { register: {} }),
      };

    // Save Affilitor
    case SAVELOADINGPAFFILITOR:
      return {
        ...state,
        saveLoadingAffilitor: true,
        saveSuccessAffilitor: null,
        saveFailedAffilitor: null,
      };
    case SAVESUCCESSAFFILITOR:
      return {
        ...state,
        saveLoadingAffilitor: false,
        saveSuccessAffilitor: action.payload || "",
        saveFailedAffilitor: null,
      };
    case SAVEFAILEDAFFILITOR:
      return {
        ...state,
        saveLoadingAffilitor: false,
        saveSuccessAffilitor: null,
        saveFailedAffilitor: action.payload || "",
      };
    default:
      return state;
  }
};

export const saveAffiliator = (sendData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVELOADINGPAFFILITOR });
      const { data } = await apiSaveAffiliator(sendData);
      dispatch({ type: SAVESUCCESSAFFILITOR, payload: data });
    } catch (e) {
      dispatch({ type: SAVEFAILEDAFFILITOR, payload: HandelMyError(e) });
    }
  };
};

export default authentication;
