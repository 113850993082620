import axios from "axios";
import { URL, URLIAFF } from "./api.conf";

export const apiCheckUserIsRegistered = ({ token, ...params }) => {
  return axios.post(URL + "/isRegistered/affiliator", { access_token: token, ...params });
};

export const apiCheckClientIsRegistered = ({ token, ...params }) => {
  return axios.post(URL + "/isRegistered/client", { access_token: token, ...params });
};

export const apiRegisterUser = ({ token, ...params }) => {
  return axios.post(URL + "/register/affiliator", { access_token: token, ...params });
};

export const apiRegisterClient = ({ token, ...params }) => {
  return axios.post(URL + "/register/client", { access_token: token, ...params });
};

export const apiSaveAffiliator = ({ token }) => {
  return axios.post(URLIAFF + "/save/affilitor", { access_token: token });
};
